import React, { Suspense } from 'react'
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'
import './index.css'
import 'flag-icon-css/css/flag-icons.min.css'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend';
import ReactLoading from 'react-loading'
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './assets/tailwind/local-tailwind.css';
import './index.css';
import { AppProvider } from './context/AppContext';
import { PageProvider } from './context/PageContext';
import { Buffer } from 'buffer';

window.Buffer = Buffer;

// import App from './components/App/App';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ['en', 'ja'],
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: 'assets/translations/{{lng}}.json'
    }
  });

const loadingMarkup = (
  <ReactLoading className='loader' type={'spinningBubbles'} color={'var(--color-900)'} height={100} width={100} />
)

window.$silverPrice = "4999.00";
window.$silverAmount = "4999";
window.$goldPrice = "7999.00";
window.$goldAmount = "7999";

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <AppProvider>
        <PageProvider>
          <App />
        </PageProvider>
      </AppProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);